import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        // autoplay: true,
        fixedHeight: "560px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: false,
        arrows: true,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
                fixedHeight: "228px",
            },
            600: {
                fixedHeight: "unset",
                arrows: false,
                pagination: true,
            },
        },
    });
    bannerSplide.mount();
}

const covenants = document.getElementById('covenants')
if (covenants) {
    splide(covenants, {
        perPage: 5,
        arrows: true,
        pagination: false,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: false,
            },
            600: {
                perPage: 1,
            }
        }
    }).mount()
}

const posts = document.getElementById('posts')
if (posts) {
    splide(posts, {
        perPage: 3,
        arrows: false,
        pagination: false,
        breakpoints: {
            768: {
                perPage: 2,
            },
            475: {
                perPage: 1,
                width: '100%',
            }
        }
    }).mount()
}